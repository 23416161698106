import React from "react";
import PuppyGramDesktop from "../components/puppygram.jsx/Desktop";
import PuppyGramMobile from "../components/puppygram.jsx/Mobile";
import useDimensions from "../hooks/useDimensions";
import "../css/social_web.css";
import { graphql } from "gatsby";

const SocialWebPuppyGram = ({ data }) => {
  const [width] = useDimensions();
  const puppyGram = data.sanityPage.puppyGramTemp;
  const seo = data.sanityPage.seo;

  return (
    <div className="social_web_bg">
      {width &&
        (width > 768 ? (
          <PuppyGramDesktop data={puppyGram} />
        ) : (
          <PuppyGramMobile data={puppyGram} />
        ))}
    </div>
  );
};

export const query = graphql`
  query ($slug: String!) {
    sanityPage(slug: { current: { eq: $slug } }) {
      seo {
        title
        pagehandle
        ldSchema
        keywords
        description
      }
      title
      template
      slug {
        current
      }
      puppyGramTemp {
        back {
          asset {
            altText
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: WEBP
            )
          }
        }
        logo {
          asset {
            altText
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: WEBP
            )
          }
        }
        gallery {
          image {
            asset {
              altText
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: WEBP
              )
            }
          }
          alt
        }
      }
    }
  }
`;
export default SocialWebPuppyGram;
