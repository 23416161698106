import React, { useState } from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Menu from "../common/Menu";
import Lightbox from "../museum/Desktop/Lightbox";

const PuppyGramMobile = ({ data }) => {
  const [lightbox, setLightbox] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [image, setImage] = useState(data.gallery[0]);

  function handleModal(id, img) {
    setCurrentIndex(id);
    setImage(img);
    setLightbox((prevState) => !prevState);
  }

  function handleNext() {
    if (currentIndex === data.gallery.length - 1) {
      setCurrentIndex(0);
      setImage(data.gallery[0]);
    } else {
      setCurrentIndex((prevState) => prevState + 1);
      setImage(data.gallery[currentIndex + 1]);
    }
  }

  function handlePrev() {
    if (currentIndex === 0) {
      setCurrentIndex(data.gallery.length - 1);
      setImage(data.gallery[data.gallery.length - 1]);
    } else {
      setCurrentIndex((prevState) => prevState - 1);
      setImage(data.gallery[currentIndex - 1]);
    }
  }

  function onClose() {
    setLightbox(false);
  }
  return (
    <div className="social_web_mobile cemetery-mobile">
      <div className="container">
        <div className="row">
          <div className="col-sm-3 col-3">
            <Link to="/">
              <GatsbyImage
                image={data.logo.asset.gatsbyImageData}
                alt={data.logo.asset.altText || "Back"}
                className="img-fluid social_web_mobile_tatoo_1"
                loading="eager"
              />
            </Link>
          </div>
          <div className="col-sm-6 col-6">
            <center>
              <GatsbyImage
                image={data.back.asset.gatsbyImageData}
                alt={data.back.asset.altText || "Social Web Logo"}
                className="img-fluid social_web_mobile_tatoo_2"
                loading="eager"
                objectFit="contain"
              />
            </center>
          </div>
          <div className="col-sm-3 col-3">
            {/*Burger Menu Start-*/}
            <Menu />
            {/*Burger Menu End-*/}
          </div>
        </div>
        <div className="clearfix" />
        <br />
        <div className="row gallery-grid-top">
          {data.gallery.map((img, index) => (
            <div className="col-6 cemetry-grid" key={index}>
              <center>
                <div
                  className="cemetry-grid-img"
                  onClick={() => handleModal(index, img)}
                  style={{ cursor: "pointer" }}
                >
                  <GatsbyImage
                    image={img.image.asset.gatsbyImageData}
                    alt={img.image.asset.altText || "Puppy Gram"}
                    className="img-fluid zoom-img"
                    loading="lazy"
                    objectFit="contain"
                  />
                  <div className="agile-b-wrapper">
                    <i className="fa fa-search-plus" aria-hidden="true" />
                  </div>
                </div>
              </center>
            </div>
          ))}
        </div>
        <div className="clearfix"> </div>
        {!!lightbox && (
          <Lightbox
            current={image}
            handleNext={handleNext}
            handlePrev={handlePrev}
            onClose={onClose}
          />
        )}
      </div>
    </div>
  );
};

export default PuppyGramMobile;
